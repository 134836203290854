<template>
    <page
        id="user-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <div v-if="!!edited_record" class="card">
            <div class="card-body">
                <div class="columns">
                    <div
                        v-if="realtor"
                        class="column col-2 col-lg-3 col-md-4 col-sm-12"
                    >
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-square"
                        >
                            <div>
                                <image-upload-input
                                    name="image_url"
                                    :url="edited_record.image_url || ''"
                                    :alt="edited_record.name"
                                    :accepts="upload_accepts('image_url')"
                                    :uploading="!!uploading.image_url"
                                    :uploaded="uploaded.image_url||0"
                                    :error="has_error('image_url')"
                                    @upload="upload_file_list('image_url', $event)"
                                    @clear="confirm_clear_file('image_url')"
                                >
                                    <template v-slot:empty-title>
                                        {{ translate("Drag avatar image here") }}
                                    </template>
                                </image-upload-input>
                            </div>
                        </div>
                        <form-input
                            v-if="role_is_at_least_realtor(edited_record.role)"
                            id="mls_id"
                            name="mls_id"
                            v-model="edited_record.mls_id"
                            :required="true"
                            :saving="saving('mls_id')"
                            :error="has_error('mls_id')"
                            class="mt-2"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("MLS #") }}</template>
                        </form-input>
                    </div>
                    <div
                        :class="{ 'col-10': realtor, 'col-lg-9': realtor, 'col-md-8': realtor, 'col-12': !realtor }"
                        class="column col-sm-12"
                    >
                        <impersonate-button
                            v-if="!me && !!edited_record && (is_at_least_administrator || (is_realtor && !role_is_at_least_realtor(edited_record.role)))"
                            :uuid="edited_record.uuid"
                            @clicked="impersonating = $event"
                        />
                        <base-form
                            v-if="!!edited_record"
                            :has-required-fields="true"
                        >
                            <div class="columns">
                                <div
                                    :class="{'col-3': realtor, 'col-md-6': realtor, 'col-9': !realtor}"
                                    class="column col-sm-12"
                                >
                                    <form-input
                                        id="email"
                                        name="email"
                                        type="email"
                                        v-model="edited_record.email"
                                        :required="true"
                                        :saving="saving('email')"
                                        :error="has_error('email')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Email") }}</template>
                                    </form-input>
                                </div>
                                <div
                                    v-if="realtor"
                                    class="column col-6 col-md-6 col-sm-12"
                                >
                                    <form-multi-select
                                        id="neighborhood_ids"
                                        name="neighborhood_ids"
                                        v-model="edited_record.neighborhood_ids"
                                        :options="neighborhoods"
                                        :max-selection="setting('max_focus_areas')"
                                        display="chips"
                                        :compact-selection="compact_selection_label(neighborhoods, edited_record.neighborhood_ids, '{n} areas', '{n} minor areas')"
                                        :required="true"
                                        :can-toggle-all="false"
                                        :saving="saving('neighborhood_ids')"
                                        :error="has_error('neighborhood_ids')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Area(s) of focus") }}</template>
                                    </form-multi-select>
                                </div>
                                <div
                                    :class="{'col-3': realtor, 'col-md-12': realtor, 'col-3': !realtor}"
                                    class="column col-sm-12"
                                >
                                    <label class="form-label hide-md">&nbsp;</label>
                                    <feedback-button
                                        v-if="!me"
                                        :status="send_password_status"
                                        :waiting="sending_password"
                                        :block="true"
                                        @click.prevent="send_reset_password"
                                    >
                                        {{ $root.translate("Send a 'Reset Password' Link") }}
                                        <template v-slot:message>
                                            <div
                                                :class="{ 'text-success': (send_password_status === true), 'text-error': (send_password_status === false) }"
                                                class="text-center"
                                            >
                                                {{ send_password_message }}
                                            </div>
                                        </template>
                                    </feedback-button>
                                    <default-button
                                        v-else
                                        flavor="link"
                                        :block="true"
                                        @click.prevent="change_password"
                                    >
                                        {{ $root.translate("Change my password") }}
                                    </default-button>
                                </div>
                            </div>

                            <div class="columns mt-8">
                                <div class="column col-3 col-md-6 col-sm-12">
                                    <form-input
                                        id="first_name"
                                        name="first_name"
                                        v-model="edited_record.first_name"
                                        :required="true"
                                        :saving="saving('first_name')"
                                        :error="has_error('first_name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("First Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-3 col-md-6 col-sm-12">
                                    <form-input
                                        id="last_name"
                                        name="last_name"
                                        v-model="edited_record.last_name"
                                        :required="true"
                                        :saving="saving('last_name')"
                                        :error="has_error('last_name')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-3 col-md-6 col-sm-12">
                                    <form-input
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        v-model="edited_record.phone"
                                        :required="false"
                                        :saving="saving('phone')"
                                        :error="has_error('phone')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Phone") }}</template>
                                    </form-input>
                                </div>
                                <div class="column col-3 col-md-6 col-sm-12">
                                    <form-input
                                        id="mobile"
                                        name="mobile"
                                        type="tel"
                                        v-model="edited_record.mobile"
                                        :required="false"
                                        :saving="saving('mobile')"
                                        :error="has_error('mobile')"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ $root.translate("Mobile") }}</template>
                                    </form-input>
                                </div>
                            </div>
                            <address-input
                                id="address"
                                name="address"
                                :value="edited_record"
                                :required="false"
                                :errors="errors"
                                @input="save_address"
                            />
                        </base-form>
                    </div>
                </div>
            </div>
        </div>

        <websites-list
            v-if="!!edited_record && !!edited_record.id && (edited_record.role >= constants('roles', 'ROLE_REALTOR').id)"
            :user-id="edited_record.id"
            class="mt-4"
        />

        <div class="card">
            <div class="card-header">
                <div class="card-title columns">
                    <div class="column col-auto h5">{{ $root.translate("Listings") }}</div>
                    <div
                        v-if="!!edited_record && !!edited_record.id && (edited_record.role < constants('roles', 'ROLE_REALTOR').id)"
                        class="column"
                    >
                        <form-listing-link-input
                            id="linked_listing_ids"
                            v-model="edited_record.listing_ids"
                            @input="save"
                        />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <user-listings
                    v-if="!!edited_record && !!edited_record.id"
                    class="mt-4"
                    :user="edited_record"
                />
            </div>
        </div>

        <div
            v-if="is_at_least_administrator && !!edited_record"
            class="card mt-4"
        >
            <div class="card-header">
                <h6 class="card-title">
                    {{ $root.translate("Admin Zone") }}
                    <span class="ml-4 text-gray">
                        <span v-if="!edited_record.last_login_at">{{ translate("Never logged in") }}</span>
                        <span v-else>
                            {{ translate("1 login:::{n} logins", { n: edited_record.login_count }, edited_record.login_count) }}
                            &bull;
                            {{ translate("Last login") }}:&nbsp;{{ edited_record.last_login_at | nibnut.date("MMM, DD") }}&nbsp;@&nbsp;{{ edited_record.last_login_at | nibnut.date("HH:mm") }}
                        </span>
                    </span>
                </h6>
            </div>
            <div class="card-body">
                <div class="columns">
                    <div
                        class="column col-sm-12 mb-2"
                    >
                        <form-dropdown
                            id="role"
                            name="role"
                            v-model="edited_record.role"
                            :options="filtered_roles"
                            :required="true"
                            :saving="saving('role')"
                            :error="has_error('role')"
                            @input="save"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-dropdown>
                    </div>
                    <div
                        v-if="!!edited_record.invitation_expires_at"
                        class="column col-sm-12 mb-2 text-center"
                    >
                        <label
                            :class="{'text-error': invitation_expired, 'text-light-gray': !invitation_expired}"
                            class="form-label"
                        >
                            <span v-if="invitation_expired">{{ $root.translate("Invitation expired on") }}</span>
                            <span v-else>{{ $root.translate("Invitation valid until") }}</span>
                            {{ edited_record.invitation_expires_at | nibnut.date("YYYY-MM-DD @ HH:mm") }}
                        </label>
                        <default-button
                            :title="$root.translate('Re-send invitation')"
                            :block="true"
                            @click.prevent="resend_invitation(edited_record)"
                        >
                            <open-icon glyph="share" /> {{ $root.translate('Re-send invitation') }}
                        </default-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center mt-8">
            <default-button
                flavor="link"
                color="error"
                size="sm"
                @click.prevent="confirm_record_delete"
            >
                {{ $root.translate("Delete Account") }}
            </default-button>
        </div>

        <modal-dialog
            id="password-editor-dialog"
            :show.sync="changing_password"
        >
            <template v-slot:title><span class="h5">{{ $root.translate("Change my password") }}</span></template>
            <base-form
                :has-required-fields="true"
                @submit.prevent="save_password"
            >
                <password-editor
                    id="password-editor"
                    name="password"
                    v-model="new_password"
                    :required="true"
                >
                    <template v-slot:label>{{ $root.translate("Enter your new password") }}</template>
                </password-editor>
            </base-form>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving('password')"
                        class="mr-2"
                        @click.prevent="changing_password=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        :waiting="saving('password')"
                        :disabled="saving('password')"
                        color="primary"
                        class="ml-2"
                        @click.prevent="save_password"
                    >
                        {{ $root.translate("Change password") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <reinvite-dialog
            :show.sync="reinviting"
            :user="reinvite"
        />

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, handles_uploads, is_user_inviter } from "@/nibnut/mixins"
import { addl_profile_utilities, addl_misc_utilities, addl_string_utilities } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormDropdown,
    FeedbackButton,
    DefaultButton,
    ModalDialog,
    PasswordEditor,
    ImageUploadInput,
    AddressInput,
    OpenIcon
} from "@/nibnut/components"
import {
    ImpersonateButton,
    FormMultiSelect,
    WebsitesList,
    UserListings,
    FormListingLinkInput
} from "@/custom/components"

export default {
    mixins: [is_record_editor_page, handles_uploads, is_user_inviter, addl_profile_utilities, addl_misc_utilities, addl_string_utilities],
    components: {
        BaseForm,
        FormInput,
        FormDropdown,
        FeedbackButton,
        DefaultButton,
        ModalDialog,
        PasswordEditor,
        ImageUploadInput,
        AddressInput,
        OpenIcon,
        ImpersonateButton,
        FormMultiSelect,
        WebsitesList,
        UserListings,
        FormListingLinkInput
    },
    methods: {
        grab_record_id () {
            this.load_default_vocabularies()
            if(this.is_at_least_realtor) {
                const int_id = parseInt(this.$route.params.id)
                this.record_id = (`${int_id}` === `${this.$route.params.id}`) ? int_id : this.$route.params.id
                if(!this.record_id) this.record_id = this.profile_id
            } else this.record_id = this.profile_id
        },
        send_reset_password () {
            this.sending_password = true
            this.$store.dispatch("SEND_PASSWORD_RESET", {
                email: this.edited_record.email
            }).then(data => {
                this.send_password_status = true
                this.send_password_message = data.message
            }).catch(error => {
                this.send_password_status = false
                this.send_password_message = error.message
            }).then(() => {
                this.sending_password = false
            })
        },
        change_password () {
            this.new_password = ""
            this.changing_password = true
        },
        save_password () {
            this.save(this.new_password, "password").then(() => {
                if(!this.has_error("password")) this.changing_password = false
            })
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this account? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            if(this.record_id === this.profile_id) this.$router.push({ name: "dashboard" })
            else this.$router.push({ name: "user.list" })
        },
        save_address (address) {
            if(this.edited_record && this.edited_record.id) {
                this.save_data_for_record_id(this.entity, this.edited_record.id, address)
            }
        }
    },
    computed: {
        can_reload () {
            return this.profile_id && !this.impersonating
        },
        default_back () {
            if(this.is_at_least_administrator) return { title: this.$root.translate(this.entityName, {}, 2), href: { name: "user.list" } }
            return { title: this.$root.translate("Dashboard"), href: { name: "home" } }
        },
        me () {
            const record = this.edited_record
            return !!this.profile_id && !!record && ((this.profile_id === record.uuid) || (this.profile_id === record.id))
        },
        realtor () {
            return !!this.edited_record && this.role_is_at_least_realtor(this.edited_record.role)
        },
        roles () {
            return Object.values(this.constants("roles"))
        },
        filtered_roles () {
            if(!this.is_developer) return this.roles.filter(role => role.id !== this.constants("roles", "ROLE_DEVELOPER").id)
            return this.roles
        },
        invitation_expired () {
            const record = this.edited_record
            if(!!record && !!record.invitation_expires_at) return this.$dayjs(record.invitation_expires_at).isBefore(this.$dayjs())
            return false
        }
    },
    data () {
        return {
            entity: "user",
            entityName: "User:::Users",

            changing_password: false,
            new_password: "",

            sending_password: false,
            send_password_status: null,
            send_password_message: "",

            impersonating: false,
            leaving_as_new_user: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#user-editor {
    .card {
        overflow: visible;
    }
}
</style>
